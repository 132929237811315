import React, { useMemo, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DatePicker } from "@material-ui/pickers";
import format from "date-fns/format";
import swal from "@sweetalert/with-react";
import PureModal from "react-pure-modal";
import {
  inArray,
  getDateRange,
  eraseLocalStorage,
  convertToQuery
} from "../common/Functions";
import { onClickDownloadRenata, moveCustomer } from "../common/services/affico";
import { getCountries } from "../common/Boot";
import { getBaseApi } from "../common/Routes";
import { useAppContext } from "../context/AppContext";

/* styles */
import "../styles/Affico.scss";
/* icons */
// import AddLeadIcon from "../assets/images/icons/addlead-aqua.svg";
// import AddAffiliateIcon from "../assets/images/icons/addaffiliate-aqua.svg";
import ReloadIcon from "../assets/images/icons/reload-white.svg";
import DownloadIcon from "../assets/images/icons/download-white.svg";
import FiltersIcon from "../assets/images/icons/filters-white.svg";
import ApproveIcon from "../assets/images/icons/approve-aqua.svg";
import CancelIcon from "../assets/images/icons/cancel-blue.svg";
import SendIcon from "../assets/images/icons/send.svg";
/* components */
import Header from "../components/Header.jsx";
import Sidebar from "../components/Sidebar.jsx";
import Kpis from "../components/Kpis.jsx";
import Loader, { TableLoader } from "../components/Loader";
import { AfficoFilter } from "../affico/atoms";
import AffMoveCustomer from "../components/AffMoveCustomer/AffMoveCustomer";
import Searchables from "../components/Searchables/Searchables";

export default function Affico(props) {
  const [{ sessionId, permissions, boot, user: { } }] = useAppContext();
  const [loading, setLoading] = useState(true);
  const [aux, setAux] = useState(false);
  const [addAffModal, setAddAffModal] = useState(false);
  const [addLeadModal, setAddLeadModal] = useState(false);
  const [addLeadsModal, setAddLeadsModal] = useState(false);
  const [showLeadsDetails, setShowLeadsDetails] = useState(false);
  const [showFTDsDetails, setShowFTDsDetails] = useState(false);
  const [showCFTDsDetails, setShowCFTDsDetails] = useState(false);
  const [showTotalFTDsDetails, setShowTotalFTDsDetails] = useState(false);
  const [modalSendFTD, setModalSendFTD] = useState(false);
  const [inputIp, setInputIp] = useState(3);
  const [filter, setFilter] = useState({
    dateType: "Today",
    dateStart: new Date(),
    dateEnd: new Date(),
    countries: "ALL",
    affiliates: "ALL",
    statuses: "ALL",
    businessUnits: "ALL",
    receivingBusiness: "ALL",
    ftds: 0
  });
  const [activeFilters, setActiveFilters] = useState({});
  const [currentFilter, setCurrentFilter] = useState("Today");
  const [typeDate, setTypeDate] = useState(true);
  let history = useHistory();

  const [afficoInfo, setAfficoInfo] = useState([]);
  const [affiliateLeads, setAffiliateLeads] = useState([]);
  const [affiliateFTDS, setAffiliateFTDS] = useState([]);
  const [affiliateCFTDS, setAffiliateCFTDS] = useState([]);
  const [affiliateTotalFTDS, setAffiliateTotalFTDS] = useState([]);
  const [catalogues, setCatalogues] = useState({});
  const [kpis, setKpis] = useState({});
  const [affiliate, setAffiliate] = useState();
  // const [loadingMove, setLoadingMove] = useState(false);
  const file = useRef();

  const [approvedLeads, setApprovedLeads] = useState([]);
  const [sendLeadsLeverate, setSendLeadsLeverate] = useState([]);
  const [dataWhere, setDataWhere] = useState("");
  // elements
  const leadsColumnRef = useRef(null);
  const FTDColumnRef = useRef(null);
  const CFTDColumnRef = useRef(null);
  const TotalFTDColumnRef = useRef(null);
  const [activeLeadsTable, setActiveLeadsTable] = useState(false);

  const getAfficoInfo = async () => {
    setLoading(true);
    setAfficoInfo([]);
    setShowLeadsDetails(false);
    setShowFTDsDetails(false);
    setShowCFTDsDetails(false);
    setShowTotalFTDsDetails(false);
    const { dateStart, dateEnd, ...filtersSearch } = filter;
    try {
      const request = await fetch(
        getBaseApi() +
        "/manage/AfficoV2" +
        convertToQuery({
          ...filtersSearch,
          dateStart: format(dateStart, "yyyy-MM-dd"),
          dateEnd: format(dateEnd, "yyyy-MM-dd")
        }) +
        "&action=Information",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("sessionId"),
            "Accept-Language": localStorage.getItem("moisesLang")
          }
        }
      );
      const { result, data, error } = await request.json();
      if (result) {
        setDataWhere(data.where);
        setAfficoInfo(data.affiliateSummary);
        setCatalogues(data.catalogues);
        setKpis({
          convertion: data.kpis.convertion + "%",
          ftdsAverage: data.kpis.ftdsAverage,
          leadsAverage: data.kpis.leadsAverage,
          totalFtds: data.kpis.totalFtds,
          totalLeads: data.kpis.totalLeads
        });
        setLoading(false);
      } else if (error === "EISN") {
        eraseLocalStorage();
      } else {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle1,
          text: error
        });
      }
    } catch (error) {
      swal({
        icon: "error",
        title: props.boot.swal.errorTitle1,
        text: error.message
      });
    };
  };
  const onSubmitInsertAffiliate = (e) => {
    e.preventDefault();
    swal({
      icon: "warning",
      title: "Are you sure you want to do this?",
      text: "A new affiliate will be registered",
      buttons: true
    }).then((willInsert) => {
      if (willInsert) {
        setLoading(true);
        let data = {
          action: "Affiliate",
          name: e.target[0].value,
          businessUnit: e.target[1].value,
          cpa: e.target[2].value
        };
        let ips = [];
        for (let i = 3; i < e.target.length - 3; i = i + 2) {
          ips.push(e.target[i].value);
        }
        data.ips = ips;
        fetch(getBaseApi() + "/manage/Affiliate", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("sessionId"),
            "Accept-Language": localStorage.getItem("moisesLang")
          },
          body: JSON.stringify(data)
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.result) {
              swal({
                icon: "success",
                title: "Success!",
                text: "The Affiliate was inserted successfuly!"
              });
              setAux(!aux);
              setAddAffModal(!addAffModal);
            } else if (response.error === "EISN") {
              eraseLocalStorage();
            } else {
              swal({
                icon: "error",
                title: props.boot.swal.errorTitle1,
                text: response.error
              });
            }
            setLoading(false);
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: props.boot.swal.errorTitle1,
              text: error.message
            });
            setLoading(false);
          });
      }
    });
  };
  // const onSubmitApproveLeads = (e) => {
  //   e.preventDefault();
  //   swal({
  //     icon: "warning",
  //     title: "Are you sure you want to do this?",
  //     text: "Multiple FTD's will be approved",
  //     buttons: true
  //   }).then((willUpdate) => {
  //     if (willUpdate) {
  //       setLoading(true);
  //       fetch(getBaseApi() + "/manage/Affico", {
  //         method: "PUT",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + localStorage.getItem("sessionId"),
  //           "Accept-Language": localStorage.getItem("moisesLang")
  //         }
  //       })
  //         .then((res) => res.json())
  //         .then((response) => {
  //           if (response.result) {
  //             swal({
  //               icon: "success",
  //               title: "Success!",
  //               text: "The FTD's were approved!"
  //             });
  //           } else if (response.error === "EISN") {
  //             eraseLocalStorage();
  //           } else {
  //             swal({
  //               icon: "error",
  //               title: props.boot.swal.errorTitle1,
  //               text: response.error
  //             });
  //           }
  //           setLoading(false);
  //         })
  //         .catch((error) => {
  //           swal({
  //             icon: "error",
  //             title: props.boot.swal.errorTitle1,
  //             text: error.message
  //           });
  //           setLoading(false);
  //         });
  //     }
  //   });
  // };
  const onSubmitInsertLead = (e) => {
    e.preventDefault();
    swal({
      icon: "warning",
      title: "Are you sure you want to do this",
      text: "A new lead will be inserted",
      buttons: true
    }).then((willInsert) => {
      if (willInsert) {
        let passCheck = /[a-z0-9-]{8,15}/;
        let pass = e.target[8].value;
        if (passCheck.test(pass)) {
          let data = {
            firstName: e.target[0].value,
            lastName: e.target[1].value,
            email: e.target[2].value,
            password: e.target[8].value,
            phoneCode: e.target[3].value,
            phoneNumber: e.target[4].value,
            idCrmAffiliate: e.target[6].value,
            referral: e.target[7].value,
            country: e.target[5].value
          };
          setLoading(true);
          fetch(getBaseApi() + "/manage/Customers", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("sessionId"),
              "Accept-Language": localStorage.getItem("moisesLang")
            },
            body: JSON.stringify(data)
          })
            .then((res) => res.json())
            .then((response) => {
              if (response.result) {
                swal({
                  icon: "success",
                  title: "Success!",
                  text: "The lead was successfuly inserted"
                });
                setAddLeadModal(!addLeadModal);
              } else if (response.error === "EISN") {
                eraseLocalStorage();
              } else {
                swal({
                  icon: "error",
                  title: props.boot.swal.errorTitle1,
                  text: response.error
                });
              }
              setLoading(false);
            })
            .catch((error) => {
              swal({
                icon: "error",
                title: props.boot.swal.errorTitle1,
                text: error.message
              });
              setLoading(false);
            });
        } else {
          swal({
            icon: "error",
            title: props.boot.swal.errorTitle1,
            text: "The password most have a length between 8-15 characters. Have at leats one letter and one number"
          });
        }
      }
    });
  };
  const onClickDownloadInfoAffico = () => {
    setLoading(true);
    const { dateStart, dateEnd, ...filtersSearch } = filter;
    fetch(
      getBaseApi() +
      "/manage/Downloads" +
      convertToQuery({
        ...filtersSearch,
        dateStart: format(dateStart, "yyyy-MM-dd"),
        dateEnd: format(dateEnd, "yyyy-MM-dd")
      }) +
      "&action=Download",
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("sessionId"),
          "Accept-Language": localStorage.getItem("moisesLang")
        }
      }
    )
      .then((res) => res.blob())
      .then((getExcel) => {
        let url = window.URL.createObjectURL(getExcel);
        let excel = document.createElement("a");
        excel.href = url;
        excel.download = "Affico Report.xlsx";
        document.body.appendChild(excel);
        excel.click();
        excel.remove();
        setLoading(false);
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle1,
          text: error.message
        });
        setLoading(false);
      });
  };
  const onClickDownloadLeadsInfo = () => {
    setLoading(true);
    const { dateStart, dateEnd, affiliates, ...filtersSearch } = filter;
    fetch(
      getBaseApi() +
      "/manage/Affico" +
      convertToQuery({
        ...filtersSearch,
        affiliates: [affiliate],
        dateStart: format(dateStart, "yyyy-MM-dd"),
        dateEnd: format(dateEnd, "yyyy-MM-dd")
      }) +
      "&action=Leads",
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("sessionId"),
          "Accept-Language": localStorage.getItem("moisesLang")
        }
      }
    )
      .then((res) => res.blob())
      .then((getExcel) => {
        let url = window.URL.createObjectURL(getExcel);
        let excel = document.createElement("a");
        excel.href = url;
        excel.download = "Affico Report.xlsx";
        document.body.appendChild(excel);
        excel.click();
        excel.remove();
        setLoading(false);
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle1,
          text: error.message
        });
        setLoading(false);
      });
  };
  const onClickDownloadFTDInfo = () => {
    setLoading(true);
    const { dateStart, dateEnd, affiliates, ...filtersSearch } = filter;
    fetch(
      getBaseApi() +
      "/manage/Affico" +
      convertToQuery({
        ...filtersSearch,
        affiliates: [affiliate],
        dateStart: format(dateStart, "yyyy-MM-dd"),
        dateEnd: format(dateEnd, "yyyy-MM-dd")
      }) +
      "&action=FTD",
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("sessionId"),
          "Accept-Language": localStorage.getItem("moisesLang")
        }
      }
    )
      .then((res) => res.blob())
      .then((getExcel) => {
        let url = window.URL.createObjectURL(getExcel);
        let excel = document.createElement("a");
        excel.href = url;
        excel.download = "Affico Report.xlsx";
        document.body.appendChild(excel);
        excel.click();
        excel.remove();
        setLoading(false);
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle1,
          text: error.message
        });
        setLoading(false);
      });
  };
  const onClickDownloadCFTDInfo = () => {
    setLoading(true);
    const { dateStart, dateEnd, affiliates, ...filtersSearch } = filter;
    fetch(
      getBaseApi() +
      "/manage/Affico" +
      convertToQuery({
        ...filtersSearch,
        affiliates: [affiliate],
        dateStart: format(dateStart, "yyyy-MM-dd"),
        dateEnd: format(dateEnd, "yyyy-MM-dd")
      }) +
      "&action=CFTD",
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("sessionId"),
          "Accept-Language": localStorage.getItem("moisesLang")
        }
      }
    )
      .then((res) => res.blob())
      .then((getExcel) => {
        let url = window.URL.createObjectURL(getExcel);
        let excel = document.createElement("a");
        excel.href = url;
        excel.download = "Affico Report.xlsx";
        document.body.appendChild(excel);
        excel.click();
        excel.remove();
        setLoading(false);
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle1,
          text: error.message
        });
        setLoading(false);
      });
  };
  const onClickDownloadTotalFTDInfo = () => {
    setLoading(true);
    const { dateStart, dateEnd, affiliates, ...filtersSearch } = filter;
    console.log({ affiliate });
    fetch(
      getBaseApi() +
      "/manage/Affico" +
      convertToQuery({
        ...filtersSearch,
        affiliates: [affiliate],
        dateStart: format(dateStart, "yyyy-MM-dd"),
        dateEnd: format(dateEnd, "yyyy-MM-dd")
      }) +
      "&action=TOTAL_FTDS",
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("sessionId"),
          "Accept-Language": localStorage.getItem("moisesLang")
        }
      }
    )
      .then((res) => res.blob())
      .then((getExcel) => {
        let url = window.URL.createObjectURL(getExcel);
        let excel = document.createElement("a");
        excel.href = url;
        excel.download = "Affico Report.xlsx";
        document.body.appendChild(excel);
        excel.click();
        excel.remove();
        setLoading(false);
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle1,
          text: error.message
        });
        setLoading(false);
      });
  };
  const onSubmitUploadMassiveLeads = (e) => {
    e.preventDefault();
    if (file.current.files[0]) {
      let data = new FormData();
      data.append("file", file.current.files[0]);
      data.append("bUnit", e.target[0].value);
      setLoading(true);
      swal({
        icon: "warning",
        title: "Are you sure you want to do this?",
        text: "Leads will be inserted",
        buttons: true
      }).then((willInsert) => {
        if (willInsert) {
          fetch(getBaseApi() + "/manage/Leads", {
            method: "POST",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("sessionId"),
              "Accept-Language": localStorage.getItem("moisesLang")
            },
            body: data
          })
            .then((res) => res.json())
            .then((response) => {
              if (response.result) {
                swal({
                  icon: "success",
                  title: "Success!",
                  text: "The leads were inserted successfuly!"
                });
                setAux(!aux);
              } else {
                swal({
                  icon: "error",
                  title: props.boot.swal.errorTitle1,
                  text: props.boot.swal.error[response.error]
                    ? props.boot.swal.error[response.error]
                    : response.error
                });
              }
              setLoading(false);
              setAddLeadsModal(false);
            })
            .catch((error) => {
              swal({
                icon: "error",
                title: props.boot.swal.errorTitle1,
                text: error.message
              });
              setLoading(false);
              setAddLeadsModal(false);
            });
        }
      });
    } else {
      swal({
        icon: "error",
        title: props.boot.swal.errorTitle1,
        text: "no File"
      });
    }
  };
  const getAdditionalInformation = async (affiliateData) => {
    setLoading(true);
    const res = await fetch(`${getBaseApi()}/getAdditionalInformation`, {
      method: "POST",
      body: JSON.stringify({ affiliate: affiliateData, where: dataWhere }),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("sessionId"),
        "Content-Type": "application/json"
      }
    });
    const { result, data, error } = await res.json();
    setLoading(false);
    if (result) {
      return data;
    } else {
      swal({
        icon: "error",
        title: "Error en la peticion",
        text: error
      });
      return null;
    }
  };
  const onClickGetAffiliateData = async (affiliateData) => {
    setAffiliateLeads([]);
    setAffiliate(null);
    setActiveLeadsTable(true);
    if (!showLeadsDetails) {
      setShowLeadsDetails(!showLeadsDetails);
      setShowFTDsDetails(showLeadsDetails);
      setShowCFTDsDetails(showLeadsDetails);
      setShowTotalFTDsDetails(showLeadsDetails);
    }
    const data = await getAdditionalInformation(affiliateData);
    if (data) {
      setAffiliateLeads(data.leads);
      setAffiliate(data.idAffiliate);
    }
  };
  const onClickGetAffiliateFTDS = async (affiliateFTDs) => {
    setAffiliateFTDS([]);
    setAffiliate(null);
    setActiveLeadsTable(true);
    if (!showFTDsDetails) {
      setShowLeadsDetails(showFTDsDetails);
      setShowFTDsDetails(!showFTDsDetails);
      setShowCFTDsDetails(showFTDsDetails);
      setShowTotalFTDsDetails(showFTDsDetails);
    }
    const data = await getAdditionalInformation(affiliateFTDs);
    if (data) {
      setAffiliateFTDS(data.affiliateFtd);
      setAffiliate(data.idAffiliate);
    }
  };
  const onClickGetAffiliateCFTDS = async (affiliateCFTDs) => {
    setAffiliateCFTDS([]);
    setAffiliate(null);
    setActiveLeadsTable(true);
    if (!showCFTDsDetails) {
      setShowLeadsDetails(showCFTDsDetails);
      setShowFTDsDetails(showCFTDsDetails);
      setShowCFTDsDetails(!showCFTDsDetails);
      setShowTotalFTDsDetails(showCFTDsDetails);
    }
    const data = await getAdditionalInformation(affiliateCFTDs);
    if (data) {
      setAffiliateCFTDS(data.cFtd);
      setAffiliate(data.idAffiliate);
    }
  };
  const onClickGetAffiliateTotalFTDS = async (affiliateTotalFTDs) => {
    setAffiliateTotalFTDS([]);
    setAffiliate(null);
    setActiveLeadsTable(true);
    if (!showTotalFTDsDetails) {
      setShowLeadsDetails(showTotalFTDsDetails);
      setShowFTDsDetails(showTotalFTDsDetails);
      setShowCFTDsDetails(showTotalFTDsDetails);
      setShowTotalFTDsDetails(!showTotalFTDsDetails);
    }
    const data = await getAdditionalInformation(affiliateTotalFTDs);
    if (data) {
      setAffiliateTotalFTDS([...data.affiliateFtd, ...data.cFtd]);
      setAffiliate(data.idAffiliate);
    }
  };
  const onClickGetApprovedLeads = (state) => {
    setApprovedLeads(state.selectedRows);
  };
  const onClickSendLeadsLeverate = (state) => {
    setSendLeadsLeverate(state.selectedRows);
  };
  const onClickSendToAffiliateDetails = (id) => {
    let filters = {
      dateType: filter.dateType,
      dateStart: format(filter.dateStart, "yyyy-MM-dd"),
      dateEnd: format(filter.dateEnd, "yyyy-MM-dd")
    };
    if (id) {
      history.push("/affiliate" + convertToQuery(filters) + "&affiliate=" + id);
    }
  };
  const handleDownloadRenata = async () => {
    setLoading(true);
    await onClickDownloadRenata(sessionId, filter);
    setLoading(false);
  };
  const renderInputIp = () => {
    let option = [];
    for (let i = 0; i < inputIp; i++)
      option.push(
        <article key={i} className="affico__allowed">
          <label className="hidden__label hidden__label--upper" htmlFor="">
            {props.boot.affico.allowedIP}
          </label>
          <input
            type="text"
            className="affico__ip"
            placeholder="0.0.0.0"
            required
          />
          <button
            className="affico__delete"
            type="button"
            onClick={() => {
              setInputIp(inputIp > 1 ? inputIp - 1 : 1);
            }}
          >
            <img src={CancelIcon} alt="Cancel" className="affico__icon" />
          </button>
        </article>
      );
    return option;
  };

  /* General Filter */
  const [filters, setFilters] = useState(false);

  const handleChangeFilters = (e) => {
    e.preventDefault();
    let countries = activeFilters.countries
      ? activeFilters.countries.map((e) => e.id)
      : [];
    countries = !countries.length
      ? e.target[1].value === "ALL"
        ? "ALL"
        : [e.target[1].value]
      : countries;
    let affiliates = activeFilters.affiliates
      ? activeFilters.affiliates.map((e) => e.id)
      : [];
    affiliates = !affiliates.length
      ? e.target[0].value === "ALL"
        ? "ALL"
        : [e.target[0].value]
      : affiliates;
    let statuses = activeFilters.statuses
      ? activeFilters.statuses.map((e) => e.id)
      : [];
    statuses = !statuses.length
      ? e.target[3].value === "ALL"
        ? "ALL"
        : [e.target[3].value]
      : statuses;
    let businessUnits = activeFilters.businessUnits
      ? activeFilters.businessUnits.map((e) => e.id)
      : [];
    businessUnits = !businessUnits.length
      ? e.target[4].value === "ALL"
        ? "ALL"
        : [e.target[4].value]
      : businessUnits;
    let receivingBusiness = activeFilters.receivingBusiness
      ? activeFilters.receivingBusiness.map((e) => e.id)
      : [];
    receivingBusiness = !receivingBusiness.length
      ? e.target[5].value === "ALL"
        ? "ALL"
        : [e.target[5].value]
      : receivingBusiness;
    let newFilters = {
      dateType: e.target[6].value,
      dateStart: filter.dateStart,
      dateEnd: filter.dateEnd,
      countries,
      affiliates,
      statuses,
      businessUnits,
      receivingBusiness,
      ftds: e.target[9].checked ? 1 : 0
    };
    setFilter(newFilters);
    setFilters(false);
    setLoading(true);
    setAfficoInfo([]);
    setCurrentFilter(e.target[6].value);
    setKpis({});
    setAux(!aux);
  };

  const renderActiveFilter = () => {
    let returnActive = [];
    Object.keys(activeFilters).forEach((type) => {
      activeFilters[type].forEach((item, index) => {
        returnActive.push(
          <button
            className="filters__button"
            onClick={() => deleteActiveFilter(type, index)}
            key={type + item.id}
          >
            {type}: {item.label}{" "}
            <img className="filters__icon" src={CancelIcon} alt="Close" />
          </button>
        );
      });
    });
    return returnActive;
  };

  const deleteActiveFilter = (array, index) => {
    activeFilters[array].splice(index, 1);
    if (activeFilters[array].length === 0) {
      document.getElementById(array).value = "ALL";
      activeFilters[array] = [];
    }
    setActiveFilters({
      ...activeFilters
    });
  };

  const modifyFilterState = (e) => {
    const { dateStart, dateEnd } = getDateRange(e.target.value);
    setFilter({
      ...filter,
      dateType: e.target.value,
      dateStart,
      dateEnd
    });
    if (e.target.value === "Range") {
      setTypeDate(false);
    } else {
      setTypeDate(true);
    }
  };
  const handleChange = (prop) => (event) => {
    let actFilters = activeFilters[prop] ? activeFilters[prop] : [];
    const sel = document.getElementById(prop);
    const label = sel.options[sel.selectedIndex].text;
    if (actFilters.map((e) => e.id).indexOf(event.target.value) >= 0) {
      return;
    }
    if (event.target.value === "ALL") {
      actFilters = [];
    } else {
      actFilters.push({
        id: event.target.value,
        label
      });
    }
    setActiveFilters({ ...activeFilters, [prop]: actFilters });
  };

  // const modifyFilterStateMultiple = (id) => {
  //   let valor = document.getElementById(id).value;
  //   let filtros = [];
  //   let active = [];
  //   if (valor === "ALL") {
  //     setFilter({ ...filter, [id]: "ALL" });
  //   } else if (filter[id] === "ALL" && valor !== "All") {
  //     active = [];
  //     filtros = [];
  //     let select = document.getElementById(id);
  //     let selectedOperation = select.options[select.selectedIndex].text;
  //     filtros.push(valor);
  //     active.push(selectedOperation);
  //     setFilter({
  //       ...filter,
  //       [id]: filtros,
  //     });
  //   } else if (typeof filter[id] === "object") {
  //     filtros = filter[id];
  //     let select = document.getElementById(id);
  //     let selectedOperation = select.options[select.selectedIndex].text;
  //     if (!inArray(selectedOperation)) {
  //       filtros.push(valor);
  //       setFilter({
  //         ...filter,
  //         [id]: filtros,
  //       });
  //     }
  //   }
  // };
  const renderGeneralSelect = (catalogue) => {
    let option = [];
    if (catalogues[catalogue] !== undefined) {
      catalogues[catalogue].forEach((item, i) => {
        option.push(
          <option
            key={i}
            value={catalogue !== "countries" ? item.id : item.name}
          >
            {catalogue !== "countries" ? item.name : getCountries()[item.name]}
          </option>
        );
      });
    }
    return option;
  };

  const generalData = (data, filter) => {
    let aux = data.filter((item) => {
      let a = Object.keys(item).map((element) => {
        if (typeof element !== "object") {
          if (
            item[element] &&
            item[element]
              .toString()
              .toLowerCase()
              .includes(filter.toLowerCase())
          ) {
            return true;
          }
          return false;
        }
        return false;
      });
      if (inArray(true, a)) {
        return true;
      }
      return false;
    });
    return aux;
  };
  /* Affiliate Details Data & Components */
  const [affSearch, setAffSearch] = useState("");
  const affDetailsColumn = [
    {
      name: "ID",
      minWidth: "120px",
      center: true,
      sortable: true,
      selector: "idAffiliate"
    },
    {
      name: props.boot.table.affiliate,
      selector: "affiliate",
      sortable: true,
      center: true,
      width: "288px",
      cell: (row) => (
        <>
          {inArray(
            "FBHG6k7zwYCcMcdY7XVRhmCVVODjm7adUYaiUzcNRQ9kp7feVviR8i4wzRFTRMXK",
            JSON.parse(localStorage.getItem("permissions"))
          ) ? (
            <button
              onClick={
                permissions.includes("HRkOqoaPAJlMBerNc01pmR2dh9hg2AYtJuQgejU7cb8Uuvn1vDzugmPWiEpSxKQq")                
                  ? () => onClickSendToAffiliateDetails(row.idAffiliate)
                  : undefined
              }
              type="button"
              className="table__button"
              disabled={!permissions.includes("HRkOqoaPAJlMBerNc01pmR2dh9hg2AYtJuQgejU7cb8Uuvn1vDzugmPWiEpSxKQq")}
            >
              {row.affiliate}
            </button>
          ) : (
            <p>{row.affiliate}</p>
          )}
        </>
      )
    },
    {
      name: "Leads",
      selector: "totalLeads",
      sortable: true,
      center: true,
      cell: (row) => (
        <>
          {row.totalLeads && (
            <button
              onClick={() => {
                onClickGetAffiliateData(row);
              }}
              className="table__button"
            >
              {row.totalLeads}
            </button>
          )}
        </>
      )
    },
    {
      name: "CRM",
      selector: "idBusinessUnitReceives",
      sortable: true,
      center: true,
      permission: "o7SKNqSQPwnhP2aR1KXOFQiJfX3uiaIGgV1F49ugMRCsLrXlWcyeKyj59odo9B7w"
    },
    {
      name: "FTD",
      selector: "totalAffilitateFtds",
      sortable: true,
      center: true,
      cell: (row) => (
        <>
          {row.totalAffilitateFtds && (
            <button
              onClick={() => {
                onClickGetAffiliateFTDS(row);
              }}
              className="table__button"
            >
              {row.totalAffilitateFtds}
            </button>
          )}
        </>
      )
    },
    {
      name: "CFTD",
      selector: "totalCftd",
      permission:
        "s7FWvufYsTjHSQpUpo9ECALMDELWY9f3UyPgb7dKVeX6aDSoogO6JIbLvKyGzFxS",
      sortable: true,
      center: true,
      cell: (row) => (
        <>
          {row.totalCftd && (
            <button
              onClick={() => {
                onClickGetAffiliateCFTDS(row);
              }}
              className="table__button"
            >
              {row.totalCftd}
            </button>
          )}
        </>
      )
    },
    {
      name: props.boot.table.totalFtd,
      selector: "totalFtds",
      permission:
        "azoMrZLRiUQndE5NIuC1YXLBzu2pHaLh7iYcXHTdy4H3VDak5XTzh1TV3Ei7IRwD",
      sortable: true,
      center: true,
      cell: (row) => (
        <>
          {row.totalFtds && (
            <button
              onClick={() => {
                onClickGetAffiliateTotalFTDS(row);
              }}
              className="table__button"
            >
              {row.totalFtds}
            </button>
          )}
        </>
      )
    },
    {
      name: props.boot.table.affiliateConvertion,
      selector: "affiliateConvertion",
      sortable: true,
      center: true
    },
    {
      name: props.boot.table.realConvertion,
      selector: "convertion",
      permission: "cooPVCJmAplNrvkh7Jf5XVyH6sqMTZl5xTw0jjbINjiFwfBKwZKXSLI12k6ddEWX",
      sortable: true,
      center: true
    },
    {
      name: props.boot.table.remAmount,
      selector: "remAmount",
      sortable: true,
      center: true
    },
    {
      name: "Player Value",
      selector: "lifeTimeValue",
      sortable: true,
      center: true
    }
  ];
  const createColumns = () => {
    let columns = [];
    affDetailsColumn.forEach((column, index) => {
      if (column.permission) {
        if (
          inArray(
            column.permission,
            JSON.parse(localStorage.getItem("permissions"))
          )
        ) {
          columns.push(column);
        }
      } else {
        columns.push(column);
      }
    });
    return columns;
  };
  const createColumnsLeads = () => {
    let columns = [];
    leadsColumns.forEach((column, index) => {
      if (column.permission) {
        if (
          inArray(
            column.permission,
            JSON.parse(localStorage.getItem("permissions"))
          )
        ) {
          columns.push(column);
        }
      } else {
        columns.push(column);
      }
    });
    return columns;
  };

  const AffDetailsFilter = ({ onFilter }) => (
    <div className="table__header">
      <AffMoveCustomer token={sessionId}>
        {({ toggleOpen, toggleLoading }) => (
          <Searchables onFilter={onFilter} toggleOpen={toggleOpen} toggleLoading={toggleLoading} />
        )}
      </AffMoveCustomer>
    </div>
  );
  const affDetailsComponent = useMemo(() => {
    return <AffDetailsFilter onFilter={(e) => setAffSearch(e.target.value)} />;
  }, []);
  /* Leads Details Data & Components */
  const [leadsSearch, setleadsSearch] = useState("");
  const leadsColumns = [
    {
      name: "No",
      selector: "id",
      sortable: true,
      center: true,
      cell: (row, index) => <div>{index + 1}</div>
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      center: true,
      minWidth: "300px"
    },
    {
      name: props.boot.table.affiliate,
      selector: "affiliate",
      sortable: true,
      center: true
    },
    {
      name: "CRM",
      selector: "businessUnit",
      sortable: true,
      center: true,
      minWidth: "150px"
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      center: true,
      minWidth: "175px"
    },
    {
      name: props.boot.table.country,
      selector: "country",
      sortable: true,
      center: true
    },
    {
      name: props.boot.table.insertDate,
      selector: "insertDate",
      sortable: true,
      center: true,
      width: "200px"
    },
    {
      name: props.boot.table.ftdDate,
      selector: "ftdDate",
      permission:
        "s7FWvufYsTjHSQpUpo9ECALMDELWY9f3UyPgb7dKVeX6aDSoogO6JIbLvKyGzFxS",
      sortable: true,
      center: true,
      width: "200px"
    },
    {
      name: "Referral",
      selector: "referral",
      sortable: true,
      center: true,
      width: "300px",
      permission:
      "8Dd6po4By5sp9gIYdDUW6L4ZbFCcYnIDoq2w6knMvvK51ACd4xeSOZCaGdyQXsvK",
    },
  ];
  // const handleShowOptionsToMove = ({ business, tpId }, show = true) => {
  //   setBuToMove({ business, tpId });
  //   setModalToMove(show);
  //   if (!show) setLoadingMove(show);
  // };
  // const handleMoveCustomer = async (event) => {
  //   event.preventDefault();
  //   setLoadingMove(true);
  //   if (buToMove.business.length === 0 || !buToMove.tpId) return;

  //   const { move_bu_to } = Object.fromEntries(new FormData(event.target).entries());
  //   setModalToMove(false);
  //   const accept = await swal({
  //     title: "Are you sure?",
  //     text: "Are you sure you want to move this customer?",
  //     icon: "warning",
  //     buttons: true,
  //     dangerMode: true,
  //   });

  //   if (!accept) {
  //     handleShowOptionsToMove({ business: [], tpId: null }, false);
  //     return;
  //   }

  //   const service = await moveCustomer({ token: sessionId, tpid: buToMove.tpId, buid: parseInt(move_bu_to, 10) });
  //   console.log({ move_bu_to, tpid: buToMove.tpId, service });
    
  //   handleShowOptionsToMove({ business: [], tpId: null }, false);
  // };
  const cftdsColumns = [
    {
      name: boot.table.action,
      center: true,
      cell: (row) => (
        <AffMoveCustomer token={sessionId}>
          {({ toggleOpen }) => (
            <button
              type="button"
              className="table__move"
              onClick={() => {
                toggleOpen(row);
              }}
            >
              <img src={SendIcon} alt={boot.affico["move-customer"]} className="table__move-icon" />
            </button>
          )}
        </AffMoveCustomer>
      ),
      permission: "FkaTb11TJ85sfRETeA2DjUruDI45HyKv88r5oZPptRRFD02yUNKOlj6Ak0jwrMob", // Change to new persmission
    },
  ].filter((item) => (permissions.includes(item.permission)) || !item.permission);
  const ftdsColumns = [
    {
      name: props.boot.table.affFtdDate,
      center: true,
      selector: "affiliateFtdDate",
      minWidth: "200px",
      permission: "s7FWvufYsTjHSQpUpo9ECALMDELWY9f3UyPgb7dKVeX6aDSoogO6JIbLvKyGzFxS",
    },
    ...cftdsColumns,
  ].filter((item) => (permissions.includes(item.permission)) || !item.permission);
  // .filter((item) => (permissions.includes(item.permission)));
  const LeadsFilter = ({ onFilter }) => (
    <div className="table__header">
      <input
        id="searchLeadsFilter"
        type="text"
        placeholder={props.boot.table.filter}
        aria-label="Search Input"
        onChange={onFilter}
        className="table__filter"
      />
    </div>
  );
  const leadsComponent = useMemo(() => {
    return <LeadsFilter onFilter={(e) => setleadsSearch(e.target.value)} />;
  }, []);

  const onClickApprovedCFTD = () => {
    document.getElementById("buttonApproveFtds").disabled = true;
    let data = {
      customers: approvedLeads
    };
    fetch(getBaseApi() + "/manage/Affico", {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("sessionId"),
        "Accept-Language": localStorage.getItem("moisesLang")
      }
    })
      .then((res) => res.json())
      .then((response) => {
        document.getElementById("buttonApproveFtds").disabled = false;
        if (response.result) {
          swal({
            icon: "success",
            title: "Success!",
            text: "FTDs Approved!"
          });
          getAfficoInfo();
        } else if (response.error === "EISN") {
          eraseLocalStorage();
        } else {
          swal({
            icon: "error",
            title: props.boot.swal.errorTitle1,
            text: props.boot.error[response.error]
          });
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle1,
          text: error.message
        });
        document.getElementById("buttonApproveFtds").disabled = false;
      });
  };

  const onHandleSubmitSendFTDToCrm = (e) => {
    e.preventDefault();
    if (!sendLeadsLeverate?.length) return;
    document.getElementById("buttonSendFtdsToCrm").disabled = true;
    const data = {
      crmIds: sendLeadsLeverate.map((lead) => lead.crmId),
      sendBU: e.target[0].value
    };
    swal({
      icon: "warning",
      title: "Are you sure you want to continue?",
      text: "Leads are going to send to CRM",
      buttons: true
    }).then((willDeleted) => {
      if (willDeleted) {
        setLoading(true);
        fetch(getBaseApi() + "/uploadLeadsOtherCrm", {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("sessionId"),
            "Accept-Language": localStorage.getItem("moisesLang")
          }
        })
          .then((res) => res.json())
          .then((response) => {
            if (document.getElementById("buttonSendFtdsToCrm")) {
              document.getElementById("buttonSendFtdsToCrm").disabled = false;
            }
            if (response.result) {
              swal({
                // icon: "info",
                title: "Summary response!",
                // text: JSON.stringify(response.data),
                content: (
                  <ul style={{ listStyle: "none" }}>
                    {Object.keys(response.data).map((k) => (
                      <li key={k}>
                        {k}: {response.data[k]}
                      </li>
                    ))}
                  </ul>
                )
              });
              setAux(!aux);
            } else if (response.error === "EISN") {
              eraseLocalStorage();
            } else {
              swal({
                icon: "error",
                title: props.boot.swal.errorTitle1,
                text: props.boot.error[response.error]
              });
            }
            setLoading(false);
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: props.boot.swal.errorTitle1,
              text: error.message
            });
            if (document.getElementById("buttonSendFtdsToCrm")) {
              document.getElementById("buttonSendFtdsToCrm").disabled = false;
            }
            setLoading(false);
          });
      }
    });
  };

  useEffect(() => {
    if (
      !inArray(
        "sPpFdKPt5aZfrQbA58VAp0eSCCwZbFHOlOjocoQNB8ed3oHPZtO6D3xlCRgVPDBp",
        JSON.parse(localStorage.getItem("permissions"))
      )
    ) {
      props.history.push("/welcome");
    }
    getAfficoInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aux]);

  useEffect(() => {
    if (filter.dateType === "Range") setTypeDate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);
  useEffect(() => {
    if (activeLeadsTable && leadsColumnRef.current) {
      leadsColumnRef.current.scrollIntoView({ behavior: "smooth" });
      setActiveLeadsTable(false);
    }
    if (activeLeadsTable && FTDColumnRef.current) {
      FTDColumnRef.current.scrollIntoView({ behavior: "smooth" });
      setActiveLeadsTable(false);
    }
    if (activeLeadsTable && CFTDColumnRef.current) {
      CFTDColumnRef.current.scrollIntoView({ behavior: "smooth" });
      setActiveLeadsTable(false);
    }
    if (activeLeadsTable && TotalFTDColumnRef.current) {
      TotalFTDColumnRef.current.scrollIntoView({ behavior: "smooth" });
      setActiveLeadsTable(false);
    }
  }, [activeLeadsTable, leadsColumnRef, FTDColumnRef, CFTDColumnRef, TotalFTDColumnRef, loading]);

  return (
    <>
      <Sidebar boot={props.boot} function={props.language} />
      {/* {loadingMove ? <Loader /> : null} */}
      <main className="general">
        <Header boot={props.boot} title="Affico" function={props.language} />
        <Kpis boot={props.boot} kpis={kpis} filter={currentFilter === "Today"} grids="five" />
        <section className="affico">
          {/* <div className="affico__buttons">
            {inArray(
              "zPxfeHdUXvf8EiUpnCGsHlateYdWXjDREUDCBA5aNyDLutySSszC85AHloxfQUep",
              JSON.parse(localStorage.getItem("permissions"))
            ) && (
              <>
                <button
                  onClick={() => setAddLeadModal(true)}
                  className="affico__action"
                >
                  <img
                    className="affico__icon"
                    src={AddLeadIcon}
                    alt="Add Lead Icon"
                  />
                  {props.boot.affico.addNewLead}
                </button>
                <div className="affico__massive">
                  <label htmlFor="uploadLeads" className="affico__action">
                    <img
                      className="affico__icon"
                      src={AddLeadIcon}
                      alt="Add Lead Icon"
                    />
                    {props.boot.affico.addLeads}
                  </label>
                  <input
                    type="file"
                    id="uploadLeads"
                    className="affico__input"
                    accept=".csv"
                    ref={file}
                    onChange={(e) => setAddLeadsModal(!!e.target.files[0])}
                    onClick={(e) => {
                      if (file.current) {
                        file.current.value = "";
                      }
                    }}
                  />
                </div>
              </>
            )}
            {inArray(
              "3V6veGY1HZKeCEuJd2RYsxQdIwbrWmzdjvSmRTG5OF71LfMGrgEPvNZfLVdvCgr4",
              JSON.parse(localStorage.getItem("permissions"))
            ) && (
              <button
                onClick={() => setAddAffModal(true)}
                className="affico__action"
              >
                <img
                  className="affico__icon"
                  src={AddAffiliateIcon}
                  alt="Add Affiliate Icon"
                />
                {props.boot.affico.addAffiliate}
              </button>
            )}
          </div> */}
          {addAffModal && (
            <div className="addaff">
              <form
                onSubmit={onSubmitInsertAffiliate}
                className="hidden hidden--upper"
              >
                <h2 className="hidden__title">
                  {props.boot.affico.addAffiliate}
                </h2>
                <div className="hidden__grid hidden__grid--three">
                  <article className="hidden__item">
                    <label
                      className="hidden__label hidden__label--upper"
                      htmlFor=""
                    >
                      {props.boot.affico.affName}
                    </label>
                    <input
                      className="hidden__input"
                      type="text"
                      id="affName"
                      required
                    />
                  </article>
                  <article className="hidden__item">
                    <label
                      className="hidden__label hidden__label--upper"
                      htmlFor=""
                    >
                      Business Units
                    </label>
                    <select
                      className="hidden__input"
                      name="affBusinessUnit"
                      id="affBusinessUnit"
                      type="text"
                    >
                      <option value="0">{props.boot.general.all}</option>
                      {catalogues.businessUnits.map((bu) => (
                        <option key={bu.id} value={bu.id}>
                          {bu.name}
                        </option>
                      ))}
                    </select>
                  </article>
                  <article className="hidden__item">
                    <label
                      className="hidden__label hidden__label--upper"
                      htmlFor=""
                    >
                      {props.boot.affico.convertionCost}
                    </label>
                    <input
                      className="hidden__input"
                      type="text"
                      id="convertionCost"
                      required
                    />
                  </article>
                  {/* <article className="hidden__item">
            <label
              className="hidden__label hidden__label--upper"
              htmlFor=""
            >
              {props.boot.affico.allowedIP}
            </label>
            <input
              className="hidden__input"
              type="text"
              id="allowedIP"
              required
            />
          </article> */}
                  {renderInputIp()}
                </div>
                <div className="hidden__add">
                  <button
                    onClick={() => setInputIp(inputIp + 1)}
                    type="button"
                    className="hidden__ip"
                  >
                    {props.boot.affico.addIP}
                  </button>
                </div>
                <div className="hidden__actions">
                  <button
                    type="button"
                    onClick={() => setAddAffModal(false)}
                    className="hidden__button hidden__button--cancel"
                  >
                    {props.boot.general.cancel}
                  </button>
                  <button
                    type="submit"
                    className="hidden__button hidden__button--save"
                  >
                    {props.boot.general.save}
                  </button>
                </div>
              </form>
            </div>
          )}
          {addLeadsModal && (
            <div className="addaff">
              <form
                onSubmit={onSubmitUploadMassiveLeads}
                className="hidden hidden--upper"
              >
                <h2 className="hidden__title">
                  {`${props.boot.affico.addNewLeads} "${file?.current?.files[0]?.name}"`}
                </h2>
                <p style={{ display: "none" }}>
                  firstName,lastName,email,password,phoneCode,phoneNumber,idCrmAffiliate,referral,country{" "}
                </p>
                <div className="hidden__grid hidden__grid--three">
                  <article className="hidden__item">
                    <label
                      className="hidden__label hidden__label--upper"
                      htmlFor=""
                    >
                      Business Units
                    </label>
                    <select
                      className="hidden__input"
                      name="affBusinessUnit"
                      id="affBusinessUnit"
                      type="text"
                    >
                      <option value="" hidden></option>
                      {catalogues.businessUnits.map((bu) => (
                        <option key={bu.id} value={bu.id}>
                          {bu.name}
                        </option>
                      ))}
                    </select>
                  </article>
                </div>

                <div className="hidden__actions">
                  <button
                    type="button"
                    onClick={() => {
                      if (file.current) {
                        file.current.value = "";
                      }
                      setAddLeadsModal(false);
                    }}
                    className="hidden__button hidden__button--cancel"
                  >
                    {props.boot.general.cancel}
                  </button>
                  <button
                    type="submit"
                    className="hidden__button hidden__button--save"
                  >
                    {props.boot.general.save}
                  </button>
                </div>
              </form>
            </div>
          )}
          {addLeadModal && (
            <div className="addaff">
              <form
                onSubmit={onSubmitInsertLead}
                className="hidden hidden--upper"
              >
                <h2 className="hidden__title">
                  {props.boot.affico.addNewLead}
                </h2>
                <div className="hidden__grid hidden__grid--three">
                  <article className="hidden__item">
                    <label
                      className="hidden__label hidden__label--upper"
                      htmlFor=""
                    >
                      {props.boot.table.firstName}
                    </label>
                    <input
                      className="hidden__input"
                      type="text"
                      id="firstNameLead"
                      required
                    />
                  </article>
                  <article className="hidden__item">
                    <label
                      className="hidden__label hidden__label--upper"
                      htmlFor=""
                    >
                      {props.boot.table.lastName}
                    </label>
                    <input
                      className="hidden__input"
                      type="text"
                      id="lastNameLead"
                      required
                    />
                  </article>
                  <article className="hidden__item">
                    <label
                      className="hidden__label hidden__label--upper"
                      htmlFor=""
                    >
                      {props.boot.table.email}
                    </label>
                    <input
                      className="hidden__input"
                      type="text"
                      id="emailLead"
                      required
                    />
                  </article>
                  <article className="hidden__item hidden__item--phone">
                    <span className="hidden__phone hidden__phone--code">
                      <label
                        className="hidden__label hidden__label--upper"
                        htmlFor=""
                      >
                        {props.boot.affico.code}
                      </label>
                      <input
                        className="hidden__input"
                        type="text"
                        id="phoneCodeLead"
                        maxLength="3"
                        placeholder="+52"
                        required
                      />
                    </span>
                    <span className="hidden__phone">
                      <label
                        className="hidden__label hidden__label--upper"
                        htmlFor=""
                      >
                        {props.boot.table.phone}
                      </label>
                      <input
                        className="hidden__input"
                        type="text"
                        id="phoneLead"
                        required
                      />
                    </span>
                  </article>

                  <article className="hidden__item">
                    <label
                      className="hidden__label hidden__label--upper"
                      htmlFor=""
                    >
                      {props.boot.table.country}
                    </label>
                    <select
                      className="hidden__input"
                      id="countryLead"
                      defaultValue={filter.countries}
                      required
                    >
                      {renderGeneralSelect("countries")}
                    </select>
                  </article>
                  <article className="hidden__item">
                    <label
                      className="hidden__label hidden__label--upper"
                      htmlFor=""
                    >
                      {props.boot.table.affiliate}
                    </label>
                    <select
                      name="affiliate"
                      id="affiliateLead"
                      className="hidden__select"
                      required
                    >
                      <option defaultValue hidden value="">
                        {props.boot.table.affiliate}
                      </option>
                      {renderGeneralSelect("affiliates")}
                    </select>
                  </article>
                  <article className="hidden__item">
                    <label
                      className="hidden__label hidden__label--upper"
                      htmlFor=""
                    >
                      {props.boot.table.referral}
                    </label>
                    <input
                      className="hidden__input"
                      type="text"
                      id="referralLead"
                      required
                    />
                  </article>
                  <article className="hidden__item">
                    <label
                      className="hidden__label hidden__label--upper"
                      htmlFor=""
                    >
                      {props.boot.login.password}
                    </label>
                    <input
                      className="hidden__input"
                      type="text"
                      id="passLead"
                      minLength="8"
                      maxLength="15"
                      required
                    />
                  </article>
                </div>

                <div className="hidden__actions">
                  <button
                    type="button"
                    onClick={() => setAddLeadModal(false)}
                    className="hidden__button hidden__button--cancel"
                  >
                    {props.boot.general.cancel}
                  </button>
                  <button
                    type="submit"
                    className="hidden__button hidden__button--save"
                  >
                    {props.boot.general.save}
                  </button>
                </div>
              </form>
            </div>
          )}
          <div className="table" id="afficoDetails">
            <div className="table__initial">
              <h4 className="table__title">
                {props.boot.affico.affiliateDetails}
              </h4>
              <span className="table__operations">
                <button
                  onClick={() => setAux(!aux)}
                  className="table__action table__action--upper"
                >
                  <img
                    src={ReloadIcon}
                    alt=""
                    className="table__icon table__icon--upper"
                  />
                </button>
                {inArray(
                  "p6JMZBbfFsnBzGCZwKHn8oztcEyvFQjTzhW8DisL9PgVk79Hy6uY1QiGESeS17qi",
                  JSON.parse(localStorage.getItem("permissions"))
                ) && (
                    <button
                      onClick={() => onClickDownloadInfoAffico()}
                      className="table__action table__action--upper"
                      title={props.boot.affico.downloadTable}
                    >
                      <img
                        src={DownloadIcon}
                        alt=""
                        className="table__icon table__icon--upper"
                      />
                    </button>
                  )}
                {inArray(
                  "7c7534fea64cab1b4090669601c9b63b3bbb893bf8133fee2eea8c16ce08a09e",
                  JSON.parse(localStorage.getItem("permissions"))
                ) && (
                    <button
                      onClick={handleDownloadRenata}
                      className="table__action table__action--upper"
                      title={props.boot.affico.downloadCustomersInfo}
                    >
                      <img
                        src={DownloadIcon}
                        alt=""
                        className="table__icon table__icon--upper"
                      />
                    </button>
                  )}

                {/* <button onClick={() => onClickPrintInfoAffico()} className="table__action table__action--upper">
          <span style={{ color: "#fff"}}>
            {props.boot.table.print}
          </span>
        </button> */}
                <button
                  onClick={() => {
                    setFilters(!filters);
                    setActiveFilters({
                      countries: Array.isArray(filter.countries)
                        ? activeFilters.countries.filter((e) => {
                          return filter.countries.indexOf(e.id) >= 0;
                        })
                        : [],
                      affiliates: Array.isArray(filter.affiliates)
                        ? activeFilters.affiliates.filter((e) => {
                          return filter.affiliates.indexOf(e.id) >= 0;
                        })
                        : [],
                      statuses: Array.isArray(filter.statuses)
                        ? activeFilters.statuses.filter((e) => {
                          return filter.statuses.indexOf(e.id) >= 0;
                        })
                        : [],
                      businessUnits: Array.isArray(filter.businessUnits)
                        ? activeFilters.businessUnits.filter((e) => {
                          return filter.businessUnits.indexOf(e.id) >= 0;
                        })
                        : [],
                      receivingBusiness: Array.isArray(filter.receivingBusiness)
                        ? activeFilters.receivingBusiness.filter((e) => {
                          return filter.receivingBusiness.indexOf(e.id) >= 0;
                        })
                        : []
                    });
                  }}
                  className="table__action table__action--upper"
                >
                  <img
                    src={FiltersIcon}
                    alt=""
                    className="table__icon table__icon--upper"
                  />
                </button>
              </span>
            </div>
            {filters && (
              <>
                <div className="hidden">
                  <h2 className="hidden__title">{props.boot.affico.filters}</h2>
                  <form onSubmit={handleChangeFilters}>
                    <div className="hidden__grid hidden__grid--five">
                      <article className="hidden__item">
                        <label className="hidden__label" htmlFor="">
                          {props.boot.table.affiliate}
                        </label>
                        <select
                          defaultValue={
                            filter.affiliates === "ALL"
                              ? filter.affiliates
                              : filter.affiliates[filter.affiliates.length - 1]
                          }
                          onChange={handleChange("affiliates")}
                          className="hidden__input"
                          name="affiliates"
                          id="affiliates"
                          type="text"
                        >
                          <option value="ALL">{props.boot.general.all}</option>
                          {renderGeneralSelect("affiliates")}
                        </select>
                      </article>
                      <article className="hidden__item">
                        <label className="hidden__label" htmlFor="">
                          {props.boot.table.country}
                        </label>
                        <select
                          id="countries"
                          defaultValue={
                            filter.countries === "ALL"
                              ? filter.countries
                              : filter.countries[filter.countries.length - 1]
                          }
                          className="hidden__input"
                          onChange={handleChange("countries")}
                        >
                          <option value="ALL">{props.boot.general.all}</option>
                          {renderGeneralSelect("countries")}
                        </select>
                      </article>
                      <article
                        className="hidden__item"
                        style={{ display: "none" }}
                      >
                        <label className="hidden__label" htmlFor="">
                          {props.boot.affico.hasFtd}
                        </label>
                        <select className="hidden__input" type="text">
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </article>
                      <article className="hidden__item">
                        <label className="hidden__label" htmlFor="">
                          Status
                        </label>
                        <select
                          name="statuses"
                          id="statuses"
                          defaultValue={filter.statuses}
                          className="hidden__input"
                          onChange={handleChange("statuses")}
                        >
                          <option value="ALL">{props.boot.general.all}</option>
                          {renderGeneralSelect("statuses")}
                        </select>
                      </article>
                      <article className="hidden__item">
                        <label className="hidden__label" htmlFor="">
                          Business Units
                        </label>
                        <select
                          id="businessUnits"
                          name="businessUnits"
                          defaultValue={filter.businessUnits}
                          className="hidden__input"
                          onChange={handleChange("businessUnits")}
                        >
                          <option value="ALL">{props.boot.general.all}</option>
                          {renderGeneralSelect("businessUnits")}
                        </select>
                      </article>
                      <article className="hidden__item">
                        <label className="hidden__label" htmlFor="">
                          Receiving business
                        </label>
                        <select
                          id="receivingBusiness"
                          name="receivingBusiness"
                          defaultValue={filter.receivingBusiness}
                          className="hidden__input"
                          onChange={handleChange("receivingBusiness")}
                        >
                          <option value="ALL">{props.boot.general.all}</option>
                          {renderGeneralSelect("businessUnits")}
                        </select>
                      </article>
                      <article className="hidden__item">
                        <label className="hidden__label" htmlFor="">
                          {props.boot.affico.date}
                        </label>
                        <select
                          name="date"
                          id="dateType"
                          className="hidden__select"
                          onChange={modifyFilterState}
                          defaultValue={filter.dateType}
                        >
                          <option value="Today">Today</option>
                          <option value="Yesterday">Yesterday</option>
                          <option value="This Week">This Week</option>
                          <option value="Last Week">Last Week</option>
                          <option value="This Month">This Month</option>
                          <option value="Last Month">Last Month</option>
                          <option value="This Year">This Year</option>
                          <option value="Range">Range</option>
                        </select>
                      </article>
                      <article className="hidden__item">
                        <DatePicker
                          disableToolbar
                          disabled={typeDate}
                          style={{ width: "100%" }}
                          format="yyyy-MM-dd"
                          label={props.boot.affico.dateStart}
                          value={filter.dateStart}
                          onChange={(date) =>
                            setFilter({ ...filter, dateStart: date })
                          }
                        />
                      </article>
                      <article className="hidden__item">
                        <DatePicker
                          disableToolbar
                          disabled={typeDate}
                          style={{ width: "100%" }}
                          format="yyyy-MM-dd"
                          label={props.boot.affico.dateEnd}
                          value={filter.dateEnd}
                          onChange={(date) =>
                            setFilter({ ...filter, dateEnd: date })
                          }
                        />
                      </article>
                      <article className="hidden__item">
                        <AfficoFilter
                          id="byFtds"
                          text="Only FTDS"
                          checked={!!filter.ftds}
                          onChecked={() => {
                            setFilter((prevFilter) => ({
                              ...prevFilter,
                              ftds: filter.ftds ? 0 : 1
                            }));
                          }}
                        />
                      </article>
                    </div>
                    <div className="hidden__actions">
                      <button
                        onClick={() => setFilters(false)}
                        type="button"
                        className="hidden__button hidden__button--cancel"
                      >
                        {props.boot.general.cancel}
                      </button>
                      <button
                        type="submit"
                        className="hidden__button hidden__button--save"
                      >
                        {props.boot.general.save}
                      </button>
                    </div>
                  </form>
                </div>
                <div className="filters">
                  <p className="filters__title">
                    {props.boot.affico.activeFilters}:
                  </p>
                  {renderActiveFilter()}
                </div>
              </>
            )}
            <DataTable
              columns={createColumns()}
              data={generalData(afficoInfo, affSearch)}
              className="table__container"
              pagination
              noHeader
              subHeader
              subHeaderComponent={affDetailsComponent}
              progressPending={loading && afficoInfo.length === 0}
              progressComponent={<TableLoader />}
            />
          </div>
          {showLeadsDetails && (
            <form
              // onSubmit={onSubmitApproveLeads}
              className="table table--margin"
              id="leadsDetails"
              ref={leadsColumnRef}
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="table__initial">
                <h4 className="table__title">
                  {props.boot.affico.leadsDetails}
                </h4>
                <span className="table__operations">
                  {inArray(
                    "dn4J3mURZy1UINkHLo36jg0QaqfJbqcfiqCsZJUhYdcYVXGFqGeoAQiEP3sIHPzp",
                    JSON.parse(localStorage.getItem("permissions"))
                  ) && (
                      <button
                        type="button"
                        onClick={() => onClickDownloadLeadsInfo()}
                        className="table__action table__action--upper"
                      >
                        <img
                          src={DownloadIcon}
                          alt=""
                          className="table__icon table__icon--upper"
                        />
                      </button>
                    )}
                  {/* <button className="table__add">
            <img
              className="table__image"
              src={ApproveIcon}
              alt="Add User"
            />{" "}
            {props.boot.affico.approveFtd}
          </button> */}
                  <button
                    type="button"
                    onClick={() => setShowLeadsDetails(false)}
                    className="table__action table__action--upper"
                  >
                    <img
                      src={CancelIcon}
                      alt=""
                      className="table__icon table__icon--upper"
                    />
                  </button>
                </span>
              </div>
              <DataTable
                columns={createColumnsLeads().filter(({ name }) => name !== "CRM")}
                data={generalData(affiliateLeads, leadsSearch)}
                className="table__container"
                pagination
                /* selectableRows */
                noHeader
                subHeader
                subHeaderComponent={leadsComponent}
                progressPending={loading && affiliateLeads.length === 0}
                progressComponent={<TableLoader />}
              />
            </form>
          )}
          {showFTDsDetails && (
            <div className="table table--margin" id="ftdDetails">
              <div className="table__initial" ref={FTDColumnRef}>
                <h4 className="table__title">FTD'S Details</h4>
                <span className="table__operations">
                  {inArray(
                    "CceaxBltVa0nqKdO5edFYn2xJE71mNjphrGTSkKifyXM9Q3gXwM9dSMiUNVSWzxm",
                    JSON.parse(localStorage.getItem("permissions"))
                  ) && (
                      <button
                        type="button"
                        onClick={() => onClickDownloadFTDInfo()}
                        className="table__action table__action--upper"
                      >
                        <img
                          src={DownloadIcon}
                          alt=""
                          className="table__icon table__icon--upper"
                        />
                      </button>
                    )}
                  <button
                    type="button"
                    onClick={() => setShowFTDsDetails(false)}
                    className="table__action table__action--upper"
                  >
                    <img
                      src={CancelIcon}
                      alt=""
                      className="table__icon table__icon--upper"
                    />
                  </button>
                </span>
              </div>
              <DataTable
                columns={[...leadsColumns, ...ftdsColumns]}
                // columns={[...leadsColumns, ...[]]}
                data={generalData(affiliateFTDS, leadsSearch)}
                className="table__container"
                pagination
                noHeader
                subHeader
                subHeaderComponent={leadsComponent}
                progressPending={loading && affiliateFTDS.length === 0}
                progressComponent={<TableLoader />}
              />
            </div>
          )}
          {showCFTDsDetails && (
            <div className="table table--margin" id="cftdDetails">
              <div className="table__initial" ref={CFTDColumnRef}>
                <h4 className="table__title">CFTD'S Details</h4>
                <span className="table__operations">
                  {inArray(
                    "l4vzQChPUop3PVJzk8ibCJHTEJn4jAdHzBDUy8C7wfUYckGFXiDwJJPfz9dzkDkf",
                    JSON.parse(localStorage.getItem("permissions"))
                  ) && (
                      <button
                        className="table__add"
                        id="buttonApproveFtds"
                        onClick={(e) => onClickApprovedCFTD()}
                      >
                        <img
                          className="table__image"
                          src={ApproveIcon}
                          alt="Approved CFTD"
                        />{" "}
                        {props.boot.affico.approveFtd}
                      </button>
                    )}
                  {inArray(
                    "PmEz7c4bKOpqRSUTpjWfqMSdPe3N6Ng5g7NNfm0E1ZRMQHlK1KpiiILn6oIfE1OY",
                    JSON.parse(localStorage.getItem("permissions"))
                  ) && (
                      <button
                        type="button"
                        onClick={() => onClickDownloadCFTDInfo()}
                        className="table__action table__action--upper"
                      >
                        <img
                          src={DownloadIcon}
                          alt=""
                          className="table__icon table__icon--upper"
                        />
                      </button>
                    )}
                  <button
                    type="button"
                    onClick={() => setShowCFTDsDetails(false)}
                    className="table__action table__action--upper"
                  >
                    <img
                      src={CancelIcon}
                      alt=""
                      className="table__icon table__icon--upper"
                    />
                  </button>
                </span>
              </div>
              <DataTable
                columns={[...createColumnsLeads(), ...cftdsColumns]}
                data={generalData(affiliateCFTDS, leadsSearch)}
                className="table__container"
                pagination
                selectableRows
                onSelectedRowsChange={onClickGetApprovedLeads}
                noHeader
                subHeader
                subHeaderComponent={leadsComponent}
                progressPending={loading && affiliateCFTDS.length === 0}
                progressComponent={<TableLoader />}
              />
            </div>
          )}
          {showTotalFTDsDetails && (
            <div className="table table--margin" id="totalFtdDetails">
              <div className="table__initial" ref={TotalFTDColumnRef}>
                <h4 className="table__title">Total FTD'S Details</h4>
                <span className="table__operations">
                  {inArray(
                    "ExISrnsC7xd988exXyvZJShNM0DRfHWHibpx2UXYV4hdlBtxLvzagq4bEg84ipxG",
                    JSON.parse(localStorage.getItem("permissions"))
                  ) && (
                      <button
                        className="table__add table__send"
                        id="buttonSendFtdsToCrm"
                        onClick={(e) => setModalSendFTD(true)}
                        disabled={!sendLeadsLeverate?.length}
                      >
                        {props.boot.affico.sendFtdToCrm}
                      </button>
                    )}
                  {/* <button className="table__add">
            <img
              className="table__image"
              src={ApproveIcon}
              alt="Add User"
            />{" "}
            {props.boot.affico.approveFtd}
          </button> */}
                  {inArray(
                    "zdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM",
                    JSON.parse(localStorage.getItem("permissions"))
                  ) && (
                      <button
                        type="button"
                        onClick={() => onClickDownloadTotalFTDInfo()}
                        className="table__action table__action--upper"
                      >
                        <img
                          src={DownloadIcon}
                          alt=""
                          className="table__icon table__icon--upper"
                        />
                      </button>
                    )}
                  <button
                    type="button"
                    onClick={() => setShowTotalFTDsDetails(false)}
                    className="table__action table__action--upper"
                  >
                    <img
                      src={CancelIcon}
                      alt=""
                      className="table__icon table__icon--upper"
                    />
                  </button>
                </span>
              </div>
              <DataTable
                columns={[...createColumnsLeads(), ...ftdsColumns]}
                // columns={[...createColumnsLeads(), ...[]]}
                data={generalData(affiliateTotalFTDS, leadsSearch)}
                className="table__container"
                pagination
                selectableRows={inArray(
                  "ExISrnsC7xd988exXyvZJShNM0DRfHWHibpx2UXYV4hdlBtxLvzagq4bEg84ipxG",
                  JSON.parse(localStorage.getItem("permissions"))
                )}
                onSelectedRowsChange={onClickSendLeadsLeverate}
                selectableRowDisabled={(row) => row.sendToCrm !== "0"}
                noHeader
                subHeader
                subHeaderComponent={leadsComponent}
                progressPending={loading && affiliateTotalFTDS.length === 0}
                progressComponent={<TableLoader />}
              />
            </div>
          )}
        </section>
      </main>
      {/* <PureModal
        isOpen={modalToMove}
        closeButton="close"
        closeButtonPosition="header"
        width="50%"
        onClose={() => {
          handleShowOptionsToMove({ business: [], tpId: null }, false);
        }}
      >
        <form onSubmit={handleMoveCustomer} className="moveto">
          <article className="hidden__item">
            <label className="hidden__label" htmlFor="">
              Move to
            </label>
            <select
              className="hidden__input"
              name="move_bu_to"
              id="move_bu_to"
              type="text"
            >
              {buToMove.business.map((bu) => (
                <option key={bu.idCrmBusinessUnit} value={bu.idCrmBusinessUnit}>{bu.name}</option>
              ))}
            </select>
            <button type="submit" className="moveto__submit">
              Move
            </button>
          </article>
        </form>
      </PureModal> */}
      <PureModal
        isOpen={modalSendFTD}
        closeButton="close"
        closeButtonPosition="bottom"
        width="300px"
        onClose={() => {
          setModalSendFTD(false);
          return true;
        }}
      >
        <form onSubmit={onHandleSubmitSendFTDToCrm}>
          <h2 className="hidden__title">{props.boot.affico.sendToCRM}</h2>
          <div className="">
            <article className="hidden__item">
              <label className="hidden__label hidden__label--upper" htmlFor="">
                Business Units
              </label>
              <select
                className="hidden__input"
                name="affBusinessUnit"
                id="affBusinessUnit"
                type="text"
                required
              >
                <option value="" hidden>
                  {"Business Units"}
                </option>
                {catalogues?.businessUnitsReceiver?.map((bu) => (
                  <option key={bu.id} value={bu.id}>
                    {bu.name}
                  </option>
                ))}
              </select>
            </article>
          </div>
          <div className="tpid__actions">
            <button
              type="button"
              onClick={() => setModalSendFTD(false)}
              className="hidden__button hidden__button--cancel"
            >
              {props.boot.general.cancel}
            </button>
            <button
              type="submit"
              className="hidden__button hidden__button--save"
            >
              {props.boot.general.send}
            </button>
          </div>
        </form>
      </PureModal>
    </>
  );
}
