import React, { useEffect, useState, useRef } from "react";
import swal from "sweetalert";
import { getBaseApi } from "../common/Routes";
import { convertToQuery } from "../common/Functions";
import { useAppContext } from "../context/AppContext";

/* Styles */
import "../styles/pages/Login.scss";

/* Components */
import Language from "../components/Language";
import Loader from "../components/Loader"

/* Assets */
import Video from "../assets/video/login-bg.mp4";
import Logo from "../assets/images/logo.svg";
import EyeIcon from "../assets/images/icons/eye-white.svg";

const DEFAULT_FORM = {
  email: "",
  password: "",
  code: ""
};

export default function Login() {
  const [{ lang, boot: { login: boot, error: bootError, swal: bootSwal } }, dispatch] = useAppContext();
  const [form, setForm] = useState(DEFAULT_FORM);
  const [showPass, setShowPass] = useState(false);
  const [FA, setFA] = useState({
    show: false,
    recovery: false,
    sessionId: null
  });
  const [loader, setLoader] = useState(false);
  const codeRef = useRef(null);
  const passwordRef = useRef(null);

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };
  const handleTogglePassword = () => {
    setShowPass(!showPass);
    if (passwordRef.current) {
      passwordRef.current.focus();
    }
  };

  const genSession = (response) => {
    setLoader(false);
    if (response.result) {
      setForm(DEFAULT_FORM);
      if (response.data.authorized) {
        Object.keys(response.data).forEach((item) => { // To remove
          let param =
            typeof response.data[item] === "object"
              ? JSON.stringify(response.data[item])
              : response.data[item];
          localStorage.setItem(item, param);
        });
        let userImage = localStorage.getItem("profileImage");
        if (userImage === "" || userImage === null) {
          localStorage.setItem(
            "profileImage",
            "https://webservicesnt.org/apis/epasero/cosmetics/images/user_perfil.svg"
          );
        }

        fetch(`${getBaseApi()}/manage/Users`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${response.data.sessionId}`,
            "Accept-Language": lang
          },
          body: JSON.stringify({ action: "Alert" })
        })
          .then((res) => res.json())
          .then((res) => {
            dispatch({ type: "TOGGLE_2FA", payload: res.data });      
            dispatch({ type: "LOGIN", payload: response.data });
          });

        
      } else {
        setFA({
          ...FA,
          sessionId: response.data.sessionId,
          show: true
        });
      }
    } else {
      swal({
        title: bootSwal.errorTitle,
        text: bootError[response.error] || response.error,
        icon: "error",
      });
    }
  };
  const getLogin = async (event) => {
    event.preventDefault();
    setLoader("Autenticando");
    try {
      const data = { user: form.email, password: form.password };
      const request = await fetch(`${getBaseApi()}/get/Login${convertToQuery(data)}`, {
        headers: {
          "Accept-Language": lang
        },
      });
      const response = await request.json();
      if (response) genSession(response);
    } catch (error) {
      setLoader(false);
    }
  };
  const getLoginFA = async (event) => {
    event.preventDefault();
    setLoader("Verificando");
    try {
      const name = FA.recovery ? 'recovery' : 'code';
      const data = { [name]: form.code };
      const request = await fetch(`${getBaseApi()}/get/2FA${convertToQuery(data)}`, {
        headers: {
          Authorization: `Bearer ${FA.sessionId}`,
          "Accept-Language": lang
        },
      });
      const response = await request.json();
      if (response) genSession(response);
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    document.getElementById("video").playbackRate = 0.7;
  }, []);

  useEffect(() => {
    if (codeRef.current) {
      codeRef.current.focus();
    }
  }, [FA, codeRef]);

  return (
    <>
      {loader ? <Loader message={loader} /> : null}
      <main className="login">
        <video
          id="video"
          src={Video}
          autoPlay
          muted
          playsInline
          loop
          disablePictureInPicture
          className="login__video"
        />
        <span className="login__background" />
        <section className="login__container">
          <img src={Logo} alt="Logo" className="login__logo" loading="lazy" />
          <Language parent />
          <form onSubmit={!FA.show ? getLogin : getLoginFA} className="login__form">
            {!FA.show ? (
              <>
                <label htmlFor="email" className="login__label">
                  {boot.email}
                  <input
                    id="email"
                    name="email"
                    type="email"
                    inputMode="email"
                    className="login__input"
                    value={form.email}
                    onChange={handleChange}
                  />
                </label>
                <label htmlFor="password" className="login__label--password">
                  {boot.password}
                  <input
                    ref={passwordRef}
                    id="password"
                    name="password"
                    type={showPass ? "text" : "password"}
                    className="login__input"
                    value={form.password}
                    onChange={handleChange}
                  />
                  <button
                    type="button"
                    onClick={handleTogglePassword}
                    className="login__password"
                  >
                    <img src={EyeIcon} className="login__eye" alt="Toggle show password" />
                  </button>
                </label>
              </>
            ) : (
              <>
                <label htmlFor="code" className={`login__label${!FA.recovery ? "--pin" : "--recovery"}`}>
                  {!FA.recovery ? boot.g2fa : boot.g2faRecovery}
                  <input
                    ref={codeRef}
                    id="code"
                    name="code"
                    type="text"
                    value={form.code}
                    onChange={handleChange}
                    inputMode="numeric"
                    className={`login__input${!FA.recovery ? "--pin" : "--recovery"}`}
                    autoComplete="off"
                    maxLength={!FA.recovery ? 6 : 36}
                  />
                </label>
                <button
                  className="login__recovery"
                  type="button"
                  onClick={() => setFA({ ...FA, recovery: !FA.recovery })}
                >
                  {!FA.recovery ? boot.g2faDont : boot.g2faReturn}
                </button>
              </>
            )}
            <button type="submit" className="login__submit">
              {boot.signIn}
            </button>
          </form>
        </section>
      </main>
    </>
  );
}
